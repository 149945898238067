import React from 'react';
import '../css/Header.css';
import useMediaQuery from '../hooks/useMediaQuery';
import { IoIosClose } from "react-icons/io";
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const handleNavClick = (linkType) => {
  if (analytics) {
    logEvent(analytics, 'nav_link_click', {
      link_type: linkType
    });
  }
};

const TechHeader = () => {
  return (
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer">
          <a href="/" onClick={() => handleNavClick('home')}>HOME</a>
        </span>
        <span id="rightContainer">
          <a className="navLink" href="/teaching" onClick={() => handleNavClick('teaching')}>TEACHING</a>
        </span>
      </div>
    </div>
  )
}

const MusicHeader = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const handleMenuToggle = (isOpening) => {
    if (analytics) {
      logEvent(analytics, 'mobile_menu_toggle', {
        action: isOpening ? 'open' : 'close'
      });
    }
    setShowMenu(isOpening);
  };

  return (
    isMobile
    ?
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer">
          <a href="/" onClick={() => handleNavClick('home')}>HOME</a>
        </span>

        <div className={showMenu ? "full-screen-modal open" : "full-screen-modal closed"}>
          <IoIosClose
            className="icon"
            style={{ position: "absolute", top: 19, right: 24 }}
            size="2rem"
            onClick={() => handleMenuToggle(false)}
          />

          <div style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", gap: 10 }}>
            <a className="navLink" href="/about" onClick={() => handleNavClick('about')}>ABOUT</a>
            <div>
              <a className="navLink" href="/commissions" onClick={() => handleNavClick('commissions')}>
                REQUEST A COMMISSION
              </a>
            </div>
            <div>
              <a className="navLink" href="/work" onClick={() => handleNavClick('work')}>
                BROWSE WORK
              </a>
            </div>
          </div>
        </div>

        <span id="rightContainer">
          <i
            className="icon fa fa-bars"
            style={{ fontSize: "1.125rem" }}
            onClick={() => handleMenuToggle(true)}
          />
        </span>
      </div>
    </div>
    :
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer">
          <a className="navLink" href="/" onClick={() => handleNavClick('home')}>HOME</a>
        </span>
        <span id="rightContainer">
          <a className="navLink" href="/about" onClick={() => handleNavClick('about')}>ABOUT</a>
          <a className="navLink" href="/commissions" onClick={() => handleNavClick('commissions')}>COMMISSION</a>
          <a className="navLink" href="/work" onClick={() => handleNavClick('work')}>WORK</a>
        </span>
      </div>
    </div>
  )
}

const Header = () => {
  return process.env.REACT_APP_WEBSITE_TYPE === "MUSIC" ? <MusicHeader /> : <TechHeader />
}

export default Header;