import React, { useEffect } from 'react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

import './css/Reset.css';
import './css/App.css';

import Main from './Components/MainNavigator';
import Footer from './Components/footer';
import Header from './Components/header';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Log page view when the app loads or route changes
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname
      });
    }
  }, [location]);

  return (
    <div className="backgroundContainer">
      <div className="appContainer">
        <Header />
        <Main/>
        <Footer/>
      </div>

      <SpeedInsights />
    </div>
  )
}

export default App;
