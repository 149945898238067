import React from 'react';
import '../../css/Arrangements.css';
import data from '../../data/data.json';
import { ImageFrame, ImageOverlay } from '../ImageOverlay/ImageOverlay';
import HighlightButton from '../HighlightButton/HighlightButton';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

function Arrangements() {
	const handleArrangementClick = (arrangement) => {
		if (analytics) {
			logEvent(analytics, 'arrangement_click', {
				arrangement_title: arrangement.Title,
			});
		}
	};

	return (
		<div className="mainContainer">
			<div className="arrangementsContainer">
				<h1>A CAPPELLA ARRANGEMENTS</h1>

				<div className="cardContainer">
					{data.arrangements.map((arrangement) => {
						if (arrangement.Ensemble !== "Mixed Choir/A Cappella" && arrangement.Visibility !== "Public")
							return null;

						return (
							<ImageOverlay
								key={arrangement.Title}
								arrangement={arrangement}
								src={`${process.env.PUBLIC_URL}/static/img/albumArt/${arrangement.ArtFile}`}
								alt={arrangement.Title}
								onClick={() => handleArrangementClick(arrangement)}
							/>
						)
					})}

					<ImageFrame>
						<div className="commission-card">
							<h4 className="parts-text" style={{ paddingBottom: 10 }}>Didn't find anything up your alley?</h4>
							<HighlightButton
								title="COMMISSION ME"
								onClick={() => {
									if (analytics) {
										logEvent(analytics, 'commission_button_click');
									}
									window.location = "/commissions";
								}}
							/>
						</div>
					</ImageFrame>
				</div>
			</div>
		</div>
	)
}

export default Arrangements;
