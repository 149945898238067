const firebaseConfig = {
    apiKey: "AIzaSyB2p2T6xzpuozbogNApD4EWpmQaSLfzHa8",
    authDomain: "portfolio-2be56.firebaseapp.com",
    databaseURL: "https://portfolio-2be56.firebaseio.com",
    projectId: "portfolio-2be56",
    storageBucket: "",
    messagingSenderId: "639657391884",
    appId: "1:639657391884:web:761162e2ff35f587",
    measurementId: "G-Q3M6MM5Q6N"
};

export default firebaseConfig;
