import React from 'react';
import '../../css/Music.css';
import HighlightButton from '../HighlightButton/HighlightButton';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

class Music extends React.Component {
  handleButtonClick = (buttonTitle) => {
    if (analytics) {
      logEvent(analytics, 'home_button_click', {
        button_title: buttonTitle
      });
    }
  };

  render() {
    return (
      <div className="mainContainer">
        <div className="bodyContainer">
          <div className="bannerContainer">
            <img src={`${process.env.PUBLIC_URL}/static/img/banner.jpeg`} alt="Lawrence Luo banner" />
          </div>

          <p style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
            <b>Lawrence Luo</b> is a composer, arranger, and multi-instrumentalist based in San Francisco with nearly two decades of experience creating and performing music.
            He has garnered awards for his contemporary compositions and arrangements, and his work for chamber ensembles, jazz-influenced bands, and a cappella groups are performed regularly in-concert.
          </p>

          <div className="actionsContainer">
            {/* <span style={{ marginRight: 10 }}><HighlightButton title={"LEARN MORE"} onClick={() => window.location = "/about"} /></span> */}
            <span style={{ marginRight: 10 }}>
              <HighlightButton
                title={"REQUEST A COMMISSION"}
                onClick={() => {
                  this.handleButtonClick('REQUEST A COMMISSION');
                  window.location = "/commissions";
                }}
              />
            </span>
            <HighlightButton
              title={"BROWSE WORK"}
              onClick={() => {
                this.handleButtonClick('BROWSE WORK');
                window.location = "/work";
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Music;
