import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import firebaseConfig from './data/firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and export it
let analytics = null;

// Only initialize analytics if it's supported in the environment
const initAnalytics = async () => {
  if (await isSupported()) {
    analytics = getAnalytics(app);
  }
};

initAnalytics();

export { analytics };